@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
/* Material Design-inspired CSS for Trello Clone */

/* Import Roboto font */

:root {
  /* Color palette */
  --primary-color: #3f51b5;
  --primary-light: #757de8;
  --primary-dark: #002984;
  --secondary-color: #ff4081;
  --secondary-light: #ff79b0;
  --secondary-dark: #c60055;
  --background-color: #f5f5f5;
  --surface-color: #ffffff;
  --error-color: #b00020;
  --text-primary: rgba(0, 0, 0, 0.87);
  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-disabled: rgba(0, 0, 0, 0.38);

  /* Typography */
  --font-family: "Roboto", sans-serif;
  --font-size-base: 16px;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  /* Elevation */
  --elevation-1: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  --elevation-2: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  --elevation-4: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);

  /* Animation */
  --animation-duration: 0.2s;
}

/* Base styles */
body {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  line-height: 1.5;
  color: var(--text-primary);
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
}

.app {
  display: flex;
  height: 100vh;
}

/* Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: var(--font-weight-medium);
}

h1 {
  font-size: 2.125rem;
}
h2 {
  font-size: 1.75rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 1.125rem;
}
h6 {
  font-size: 1rem;
}

/* Sidebar styles */
.sidebar {
  width: 256px;
  background-color: var(--surface-color);
  color: var(--text-primary);
  box-shadow: var(--elevation-2);
  overflow-y: auto;
  z-index: 2;
}

.sidebar-header {
  padding: 16px;
  background-color: var(--primary-color);
  color: white;
}

.sidebar-header h2 {
  font-size: 1.25rem;
  font-weight: var(--font-weight-medium);
}

.sidebar nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar nav ul li {
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color var(--animation-duration);
}

.sidebar nav ul li:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.project-list .project,
.board-list .board {
  padding: 8px 16px 8px 32px;
  margin: 4px 0;
  border-radius: 4px;
  transition: background-color var(--animation-duration);
}

.project-list .project.selected,
.board-list .board.selected {
  background-color: rgba(0, 0, 0, 0.08);
  font-weight: var(--font-weight-medium);
}

/* Main content styles */
.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

/* Header styles */
.app-header {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background-color: var(--primary-color);
  color: white;
  box-shadow: var(--elevation-4);
  z-index: 1;
}

.search-bar input {
  background-color: rgba(255, 255, 255, 0.15);
  border: none;
  border-radius: 4px;
  color: white;
  padding: 8px 12px;
  font-size: 1rem;
  transition: background-color var(--animation-duration);
}

.search-bar input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.search-bar input:focus {
  background-color: rgba(255, 255, 255, 0.25);
  outline: none;
}

.new-button {
  background-color: var(--secondary-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 0.875rem;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color var(--animation-duration);
}

.new-button:hover {
  background-color: var(--secondary-dark);
}

.user-profile {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.user-profile img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 16px;
}

/* Board styles */
.board {
  flex-grow: 1;
  padding: 24px;
  background-color: var(--background-color);
}

.board-header {
  margin-bottom: 24px;
}

.board-header h2 {
  display: flex;
  align-items: center;
}

.edit-icon {
  font-size: 1.25rem;
  margin-left: 8px;
  cursor: pointer;
  opacity: 0.54;
  transition: opacity var(--animation-duration);
}

.edit-icon:hover {
  opacity: 0.87;
}

.board-filters {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  margin-top: 16px;
}

.board-filters > div {
  display: flex;
  flex-direction: column;
}

.board-filters input,
.board-filters select {
  padding: 8px 12px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color var(--animation-duration);
}

.board-filters input:focus,
.board-filters select:focus {
  outline: none;
  border-color: var(--primary-color);
}

.columns {
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  overflow-x: auto;
  padding-bottom: 24px;
}

/* Column styles */
.column {
  min-width: 272px;
  background-color: var(--surface-color);
  border-radius: 4px;
  padding: 16px;
  box-shadow: var(--elevation-1);
}

.column h3 {
  margin-bottom: 16px;
  font-weight: var(--font-weight-medium);
}

.add-task {
  width: 100%;
  padding: 8px 0;
  background-color: transparent;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  text-align: left;
  font-size: 0.875rem;
  transition: color var(--animation-duration);
}

.add-task:hover {
  color: var(--text-primary);
}

/* Card styles */
.card {
  background-color: var(--surface-color);
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 8px;
  box-shadow: var(--elevation-1);
  transition: box-shadow var(--animation-duration);
}

.card:hover {
  box-shadow: var(--elevation-2);
}

.card-priority {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  margin-bottom: 8px;
}

.card-priority.high {
  background-color: #ffcdd2;
  color: #c62828;
}
.card-priority.medium {
  background-color: #fff9c4;
  color: #f9a825;
}
.card-priority.low {
  background-color: #c8e6c9;
  color: #2e7d32;
}

.card h4 {
  margin: 8px 0;
  font-weight: var(--font-weight-medium);
}

.card-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
  color: var(--text-secondary);
  margin-top: 8px;
}

.card-members {
  display: flex;
}

.member-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--primary-light);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: var(--font-weight-medium);
  margin-right: -6px;
}

.card-stats {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

/* Responsive design */
@media (max-width: 768px) {
  .app {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: auto;
  }

  .board-filters {
    flex-direction: column;
  }
}

/* ========= custom scrollbar ========= */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(58, 70, 38, 0.9);
}

::-webkit-scrollbar-track {
  box-shadow: none;
  background: #f1f1f1;
}

/* ========= !custom scrollbar ========= */

